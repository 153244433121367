import React, { useState } from "react";
import { Iframe, PlayButton, Thumbnail, VideoWrapper } from "../styledComponents/YouTubeEmbed";

const YouTubeEmbed = ({ videoI, Url, ThumbnailUrl }) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const handleThumbnailClick = () => {
    setIsIframeLoaded(true);
  };

  return (
    <VideoWrapper>
      {isIframeLoaded ? (
        <Iframe
          src={`${Url}?autoplay=0`} // Ensures autoplay is disabled
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : (
        <>
          <Thumbnail
            src={ThumbnailUrl}
            alt="Video Thumbnail"
            onClick={handleThumbnailClick}
          />
          <PlayButton onClick={handleThumbnailClick} />
        </>
      )}
    </VideoWrapper>
  );
};

export default YouTubeEmbed;
