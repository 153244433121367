import styled from "styled-components"
import breakpoints from "../breakpoints"
import { bold16, bold18, bold24, bold32, bold36 } from "../typography"

const StyledHeroBannerProgram = styled.section`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  75% 0;
  background-color: #953491;
  width: 100%;
  height: 63vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  

  @media screen and (min-width: ${breakpoints.md}px) {
    height: 80vh;  
    background-image: url(${props => props.imageLarge || props.image});
  }


${props => props.secondimage && `
  @media screen and (min-width: ${breakpoints.md}px) {
      &::after {
          content: " ";
          display: block;
          width: 45vw;
          height: 100%;
          bottom: 0;
          right: -10rem;
          top: 0;
          position: absolute;
          background-image: url(/images/program/kaye-lee-headshot.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 80% 0;
      }
  }
  
  @media screen and (min-width: ${breakpoints.l}px) {
      &::after {
          width: 40vw;
          height: 100%;
          right: 0;
      }
  }
`}
 

  ul li { 
    blockquote p {
      color: var(--white);
      text-shadow: 1px 3px 2px #474747;
      ${bold18}
      @media screen and (min-width: ${breakpoints.md}px) {
        ${bold24}
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        ${bold36}
      }
    }
    cite {
      color: var(--white);
      text-shadow: 1px 3px 2px #474747;
      ${bold18}
      @media screen and (min-width: ${breakpoints.md}px) {
        ${bold18}
      }
    }
  }
`

const ChildWrapperProgram = styled.div`
  position: absolute;
  top: 4rem;
  left: 0;
  height: calc(50vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  content: "";
  width: 100vw;
`

export {
  StyledHeroBannerProgram,
  ChildWrapperProgram,
}
