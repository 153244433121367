import styled from "styled-components"
import breakpoints from "./breakpoints"
import { regular14, regular18, bold16, bold24, bold36, regular24, bold30, bold20, bold28, bold32, regular22, regular12 } from "./typography"

const StyledProgramLanderContainer = styled.div`
    

`;
const StyledProgramLanderSectionBlock = styled.section`
  display: block;
  max-width: 100.4rem;
  margin: 0 auto;
  padding: calc((16 / 320) * 100%);
  
  

  p{
    margin: 0 auto;
    text-align: left;
    ${regular18}
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${regular18}
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      ${regular22}
      ${props => props.whatsIncluded ? 'text-align: center;' : 'text-align: left;'}
      ${props => props.whatsIncluded && 'margin: 0 10rem 0'};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular24}
      margin: 0 3rem 0;
      ${props => props.whatsIncluded ? 'text-align: center;' : 'text-align: left;'}
    }

  }
  h2{
    text-align: left;
    margin: 4rem 0 1.6rem;
    ${bold24}
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin: 6rem 0 2.4rem;
      ${bold24}
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 0 0 2.4rem;
      ${bold28}
      ${props => !props.whatsIncluded ? 'text-align: left;' : 'text-align: center;'};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      margin: ${props => props.whatsIncluded ? '2rem 2rem 3.2rem' : '8rem 2rem 3.2rem 3rem'};
      ${props => props.whatsIncluded && 'text-align: center;'};
    }
  }

  h3 {
    @media screen and (min-width: ${breakpoints.md}px) {
      text-align: center;
      ${bold24}
      margin: 0 3rem 2rem;
      ${props => !props.whatsIncluded && 'text-align: left;'};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      // text-align: left;
    }
  }

  ul.dates {
  @media screen and (min-width: ${breakpoints.md}px) {
      margin: 0 3rem 4rem!important;
      list-style-type: disc;
      
    }
  }

  h4 {
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 0 18rem 0;
    }
  }

  h2, h3, h4, h5 {
    color: var(--light-purple-darker);
  }

  ul.centered-list {
    text-align: center;
  }

  button {
    // background: var(--light-purple-dark);
    border: 2px solid var(--white);
    border-bottom: 2px solid var(--white);
    border-radius: 20px;
    padding: auto 4rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin: 2rem auto;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      ${bold20}
    }
    @media screen and (min-width: ${breakpoints.l}px) { 
      ${bold24}
    }
  }

  ${props => props.whatsIncluded && `
    margin-bottom: 4rem;
    ul li h4 {
      margin-bottom: 1.6rem;
    }
    p {
      text-align: left;
      @media screen and (min-width: ${breakpoints.md}px) {
        text-align: center;
      }

      b {
        margin-bottom: 1.6rem;
        display: inline-block;

        &.inline {
          margin-bottom: 0;
        }
      }
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-top: -20rem;
      position: relative;
      background: #ffffffed;
      border-radius: 3rem;
      padding-bottom: 3.2rem;

      &:after {

        content: " ";
        display: block;
        width: 100vw;
        height: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        padding-bottom: 3rem;
        // background: #000;
      }
    }
     @media screen and (min-width: ${breakpoints.l}px) {
      margin-top: -50rem;
    }
     @media screen and (min-width: ${breakpoints.md}px) {
       ul {
        list-style-type: none;
        width: 100%;
        li {
          list-style-type: none;
        }
      }
      h4, h5 {
        ${bold20}
      }
    }
  `}
`
const StyledProgramLanderArticle = styled.article`
   ul,p, ol {
    margin-bottom: 1.6rem;
    padding-left: 0;

    &.bullet-list, &.dates {
      list-style-type: disc;
      margin-left: 1.4rem;
       li {
        list-style-type: disc;
        @media screen and (min-width: ${breakpoints.md}px) {
          ${props => props.whatsIncluded && 'list-style-type: none'};
        }
        p {
          margin-bottom: 0;
        }
       }  
    }
  @media screen and (min-width: ${breakpoints.md}px) {
    ol.bullet-list {
          list-style-type: disc;
          column-count: 2;
          column-gap: 20px;

          
      }

      ol.bullet-list li {
          display: block;
          ${regular24}
          position: relative;
          list-style-type: disc;

          &:nth-child(-n+5) {
            text-align: right;
            margin-right: 2rem;
          }
          &:nth-child(n+5) {
            // margin-left: 2rem;
          }

      }

      ol.bullet-list li::before {
          
      }
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      &.bullet-list, &.dates {
        list-style-type: none;
        width: 100%;
        margin-left: 0;
        li {
          list-style-type: none;
          p {
          margin-bottom: 0;
          }
        }  
      }

      
    }

    &.top-list {
      li {
        text-align: center;
      }
    }

    &.strong-list li p {
      font-weight: bold;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
      margin-bottom: 3.2rem;
    }
   }
   li {
    list-style-type: none;
   }

    h3 + p {
        margin-top: 1.6rem;
        text-align: left;
        @media screen and (min-width: ${breakpoints.md}px) {
          ${props => props.whatsIncluded ? 'text-align: center' : 'text-align: left'};
        }

        
    }

  ul.top-list li h3 {
    color: var(--light-purple-darkest);
    ${bold20}
    
    @media screen and (min-width: ${breakpoints.sm}px) {
      ${bold24}
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      ${bold28}
      margin: 0 7rem 5rem 7rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${bold32}
      margin-bottom: 6rem;
    }
  }

    .disclaimer {
      background: var(--grey-lightest);
      padding: 1.6rem;
      margin-top: 1.6rem;
      ${regular12}
    }

    hr.icon, hr.sparkles {
      margin: 5rem auto 5.5rem;
      padding: 0;
      border: 0;
      border-top: solid var(--light-purple-dark) 1px;
      text-align: center;  
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
      hr.icon, hr.sparkles {
        width: 70%;
        margin: 4rem auto 4rem;
      }
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      hr.icon,hr.sparkles {
      width: 50%;
        margin: 6rem auto 6rem;
      }
    }

    hr.icon:after {
      content: "🌟";
      display: inline-block;
      position: relative;
      top: -1.7rem;
      padding: 0 1rem;
      font-family: FontAwesome;
      font-size: 3rem;
      background-color: #fff; // Must Match Page Background
    }
    
    hr.sparkles:after {
      content: "💫";
      display: inline-block;
      position: relative;
      top: -1.7rem;
      padding: 0 1rem;
      font-family: FontAwesome;
      font-size: 3rem;
      background-color: #fff; // Must Match Page Background
    }
`

export {
  StyledProgramLanderSectionBlock,
  StyledProgramLanderContainer,
  StyledProgramLanderArticle
}