import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import breakpoints from "../../../styledComponents/breakpoints";
import { StyledAnchorLink, StyledRegisterNowButton } from "../../../styledComponents/button";
import { StyledProgramLanderArticle, StyledProgramLanderSectionBlock } from "../../../styledComponents/programSalesLander";
import HeroBannerProgram from "../../../components/HeroBannerProgram";
import { HeroDescriptionProgram, HeroHeaderProgram, HeroTextProgram } from "../../../styledComponents/heroBannerProgram";
import YouTubeEmbed from "../../../components/YouTubeEmbed";
import { StyledBlockQuote } from "../../../styledComponents/base";
import { regular24, regular16, regular14, regular18, regular22, regular12 } from "../../../styledComponents/typography";
import KarinaDates from "./karinaDates";

// Styled components
const Container = styled.div`
    @media screen and (min-width: ${breakpoints.md}px) {
        p {
            ${regular22}
        }  
    }
    ${props => props.whatsIncluded && `
            background: var(--grey-lightest);
            
            p {text-align:center;}
            ul {
                li p{
                    text-align: left;
                }
            }
            @media screen and (min-width: ${breakpoints.sm}px) {
                    padding-bottom: 3rem;
                h3, h4, h5 {
                    text-align: center;
                    margin-top: 3.2rem;
                }
                
                ul {
                    width: 80%;
                    li p{
                        text-align: center;
                    }
                    li strong {
                        display: block;
                    }
                }
            }

    `}

    div.disclaimer {
        background: var(--grey-lightest);
        padding: 1.6rem;
        
        
        p {
        ${regular12}
        margin-bottom: 1.6rem;
            @media screen and (min-width: ${breakpoints.md}px) {
                margin: 0 auto 1.6rem;
                max-width: 80rem;
            }
        }
    }

    ${props => props.testimoinials && `
        background: var(--light-purple-darkest);

        blockquote {
            text-align:center;
            border-radius: 1.5rem;
            border-left: 0;
            ${regular18}
            margin-bottom: 2.4rem;
            @media screen and (min-width: ${breakpoints.md}px) {
                ${regular24}
            }
        }

        footer p{
            color: var(--white);
            text-align: center;
            margin: 0 2.4rem;
        }

        button {
            margin: 2.4rem auto;
        }
    `}

    div.cost-section {
        background: var(--light-purple-light);
        margin-bottom: 2.4rem;
    }   
`;

const CountdownContainer = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;

  p{ 
    color: #ffffff;
  }
`;


const CopyContent = ( { section }) => {

    const calculateTimeLeft = () => {
        const targetDate = new Date();
        targetDate.setDate(targetDate.getDate() + 6);
        targetDate.setHours(0, 0, 0, 0);

        const now = new Date();
        const difference = targetDate - now;

        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
        return null;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, [timeLeft]);

    const video2Ref = useRef(null);

    useEffect(() => {
        if (section === "video2") {
            setTimeout(() => {
                if (video2Ref.current) {
                    video2Ref.current.scrollIntoView({ behavior: "smooth" });
                }
            }, 500); // Adjust timeout if necessary
        }
    }, [section]);


    return (
        <>
            <Container>
                <HeroBannerProgram
                    image="/images/program/hero-divine-reversal-codes-2-min.png"
                    imageLarge="/images/program/hero-divine-reversal-codes-hd-bg-only-min.png"
                    backgroundPosition="0 0"
                    largeBackgroundPosition="0 -260px"
                    largeBackgroundSize="100%"
                    type="drc-program"
                    backgroundSize="cover"
                    secondimage="/images/program/kaye-lee-headshot-min.png"

                >
                    <HeroTextProgram alignment="left" >
                        <span>Featuring...</span>
                        <HeroHeaderProgram isLander="true">
                            Divine Reversal Codes with <span>Kaye Lee</span>
                        </HeroHeaderProgram>
                        <HeroDescriptionProgram>Unlock the Healing You Weren't Meant to Have  </HeroDescriptionProgram>
                        <StyledAnchorLink inverse="true" className="learn-more" to="https://buy.stripe.com/5kA9AFeLJfbq8241av">Secure Your Spot</StyledAnchorLink>
                        {/* <CountdownContainer>
                        {timeLeft ? (
                            <p>Offer ends in: {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s</p>
                        ) : (
                            <p>Offer has ended.</p>
                        )}
                    </CountdownContainer> */}
                    </HeroTextProgram>
                </HeroBannerProgram>


                <StyledProgramLanderSectionBlock>
                    <StyledProgramLanderArticle>
                        <ul className="top-list">
                            <li>
                                <h3>What if the biggest limits on your healing and success weren't &ldquo;yours&rdquo; at all?</h3>
                            </li>
                            <li>
                                <h3>What if you could reverse the hidden forces keeping you trapped - without endless struggle, techniques that only &ldquo;sometimes&rdquo; work, or going through yet another cycle of frustration and disappointment?</h3>
                            </li>
                        </ul>

                        <YouTubeEmbed
                            Url="https://www.youtube.com/embed/kNHDVRWMbAE?autoplay=1"
                            ThumbnailUrl={'/videos/divine-reversal-codes-min.png'}
                        />


                        <h3 id="video2"
                            ref={video2Ref} style={{ marginBottom: '2.4rem', textAlign: 'center' }}>Kaye says using the Divine Reversal Codes is like spoon bending. Here is an old spoon bending video</h3>
                        <YouTubeEmbed
                            videoId="kNHDVRWMbAE"
                            Url="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/Spoon-Therapy-NOT-Spoon-Bending.mp4"
                            ThumbnailUrl={'/videos/spoon-therapy-not-spoon-bending-min.png'}
                        />




                        <p>The truth is, <b>your reality is shaped by unseen codes - powerful energetic structures embedded in duality itself</b>. These codes <b>dictate what's &ldquo;possible&rdquo; for you&hellip; and what remains forever out of reach</b>.</p>

                        <p><b>Until now</b>.</p>


                        <p>Welcome to <b>Divine Reversal Codes, a groundbreaking energetic system</b> that <b>unlocks the 5 Divine Reversal Codes</b> - allowing you to <b>break free</b> from:</p>

                        <ul className="strong-list">
                            <li>
                                <p>&#128683; The past traumas, struggles, and patterns that seem impossible to release</p>
                            </li>
                            <li>
                                <p>&#128683; The constant plateaus that stop your healing and success in their tracks</p>
                            </li>
                            <li>
                                <p>&#128683; The contradictions and confusion from different healing modalities</p>
                            </li>
                            <li>
                                <p>&#128683; The feeling of being alone, lost, and unsure of your path</p>
                            </li>
                            <li>
                                <p>&#128683; The stubborn health, relationship, and abundance issues that defy all logic</p>
                            </li>
                        </ul>
                        <hr className=" sparkles" />
                        <h2>Healing often feels like a frustrating cycle:</h2>

                        <p>You try <b>every new health program, but the results never last.</b></p>

                        <p>You've done the inner work, yet <b>past trauma and stress still weigh you down</b>.</p>

                        <p>You pour love into everyone else <b>but feel unseen, unheard, and depleted yourself</b>.</p>

                        <p>This is <b>Duality's Trap</b> - a system where effort often strengthens the very problems you're trying to escape:</p>

                        <ul className="bullet-list">
                            <li>
                                <p>The more you <b>fight against aging, the faster time seems to take its toll</b>.</p>
                            </li>
                            <li>
                                <p>The harder you <b>try to fix your body, the more resistant it becomes</b>.</p>
                            </li>
                            <li>
                                <p>The more you give to others, the harder it is to receive for yourself.</p>
                            </li>
                        </ul>


                        <p>But what if <b>healing wasn't about struggling to fix what's broken?</b></p>

                        <p>What if somewhere, in a parallel reality, <b>you have already healed?</b></p>

                        <p>Quantum physics tells us that <b>infinite versions of you exist</b> - realities where you are radiantly healthy, deeply loved, and effortlessly thriving. The Divine Reversal Codes teach you how to borrow from those versions of yourself and bring that reality here, now.</p>

                        <p><b>The Divine Reversal Codes are the missing keys</b> - allowing you to <b>step beyond duality's limitations</b> and reclaim the <b>energy, time, space, dimensions, and identities that are truly yours</b>.</p>

                        <hr className="icon" />
                        <h2>Are You Ready to Reverse the Hidden Forces Holding You Back?</h2>

                        <p>For centuries, healers and spiritual seekers have unknowingly operated within the confines of duality - only seeing part of the story. But you don't have to play by those rules anymore.</p>

                        <h3>With Divine Reversal Codes, you will:</h3>

                        <ul>
                            <li>
                                <p>&#9989; Learn to shift your Duality Position  -  stepping into a reality where healing and transformation unfold naturally.</p>
                            </li>
                            <li>
                                <p>&#9989; Tap into the limitless power of your Twin Selves - your highest and most powerful versions across dimensions.</p>
                            </li>
                            <li>
                                <p>&#9989; Reclaim lost energies, rhythms, and information that belong to you - but have been hidden until now.</p>
                            </li>
                            <li>
                                <p>&#9989; Unlock the 5 Divine Reversal Codes of Energy, Time, Space, Dimension, and Identities, allowing you to shift reality itself.</p>
                            </li>
                            <li>
                                <p>&#9989; Create lasting healing, success, and transformation in ways that finally make sense and work consistently.</p>
                            </li>
                        </ul>
                        <StyledRegisterNowButton
                            onClick={() => {

                                window.location.href = "https://buy.stripe.com/5kA9AFeLJfbq8241av";
                            }
                            }>Secure Your Spot</StyledRegisterNowButton>
                    </StyledProgramLanderArticle>
                </StyledProgramLanderSectionBlock>
            </Container>



            <Container whatsIncluded>
                <HeroBannerProgram
                    image="/images/program/hero-divine-reversal-codes-break01-min.png"
                    imageLarge=""
                    backgroundPosition="0 0"
                    largeBackgroundPosition="0 -260px"
                    largeBackgroundSize="100%"
                    type="karinaonstage"
                    backgroundSize="cover"
                >
                    <HeroTextProgram alignment="left">

                    </HeroTextProgram>
                </HeroBannerProgram>
                <StyledProgramLanderSectionBlock whatsIncluded>
                    <StyledProgramLanderArticle whatsIncluded>

                        <h2>What's Included in the Divine Reversal Codes Program?</h2>

                        <h3>2 Exclusive Live Calls Every Month with Master Kaye Lee</h3>

                        <p>Each month, you'll receive two power-packed live calls designed to accelerate your mastery of the Divine Reversal Codes:</p>

                        <p><b>Call 1: The Activation Experience </b><br /> Direct immersion into the Divine Reversal Codes so you can experience transformation firsthand. No theory - just pure energetic shifts.</p>

                        <p><b>Call 2: Mastery &amp; Practical Application</b><br /> Break down your experiences, integrate the knowledge, and apply it in powerful, real-world ways.</p>


                        <h2>Season 1: Unlocking the Quantum Multiverse</h2>
                        <h3>First Three Months of Training Focus:</h3>

                        <ul>
                            <li><h4>
                                <b>Month 1</b> Divine Reversal Code Energies &ndash; Going Beyond the Limits of Energy

                            </h4>
                                <p>Learn to tap what Master Kaye calls Primordial Origin Energy of the Quantum Multiverse and all its permutations. Many take decades of training to tap into this, if they are even able to discover the secret of its existence at all.</p>
                                <h5>&#128197; Dates of the LIVE calls</h5>
                                <ul className="dates">
                                    <li>
                                        <p>April 8th @ 9<small>am</small> EST (Session for March)</p>
                                    </li>
                                    <li>
                                        <p>April 10th @ 9<small>am</small> EST (Session for March)</p>
                                    </li>
                                </ul>
                            </li>

                            <li><h4><b>Month 2</b> Divine Reversal Code Healings &ndash; How to Tap the Support of Your Twin Self</h4><p>Discover reality-altering techniques for reaching across the Quantum Multiverse to access the support of a powerful healing ally who will always be on your side - your Twin Self.</p>
                                <h5>&#128197; Dates of the LIVE calls</h5>
                                <ul className="dates">
                                    <li>
                                        <p>May 8th @ 9<small>am</small> EST (Session for April)</p>
                                    </li>
                                    <li>
                                        <p>May 15th @ 9<small>am</small> EST (Session for April)</p>
                                    </li>
                                </ul></li>
                            <li><h4><b>Month 3</b> Divine Reversal Code Healing Month – The Healing You Were Never Meant to Have</h4><p>Apply the powerful Divine Reversal Code Energies and Healing Allies to healing the body's 9 Major Systems:</p>
                                <ol className="bullet-list">
                                    <li>Musculoskeletal system</li>
                                    <li>Respiratory system</li>
                                    <li>Circulatory system</li>
                                    <li>Digestive system</li>
                                    <li>Excretory system</li>
                                    <li>Nervous system</li>
                                    <li>Endocrine system</li>
                                    <li>Reproductive system</li>
                                    <li>Integumentary system (skin, hair, nails, exocrine glands)</li>
                                </ol>
                                <h5>&#128197; Dates of the LIVE calls</h5>
                                <ul className="dates">
                                    <li>
                                        <p>May 27th @ 9<small>am</small> EST (Session for May)</p>
                                    </li>
                                    <li>
                                        <p>May 29th @ 9<small>am</small> EST (Session for May)</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h3>A Growing Library of 10+ New Energy Audios Every Month</h3>

                        <p><b className="inline">Each month</b>, Master Kaye Lee channels the energy of his Twin Selves into 10 brand-new healing audios designed to clear 750+ health issues at the deepest level.</p>

                        <h3>These audios will work on:</h3>

                        <ul>
                            <li>
                                <p>&#10004;&#65039; Deep-rooted trauma, ancestral blockages &amp; energy attacks</p>
                            </li>
                            <li>
                                <p>&#10004;&#65039; Hidden distortions in your body, emotions &amp; mind</p>
                            </li>
                            <li>
                                <p>&#10004;&#65039; Energetic misalignments affecting health, relationships &amp; abundance</p>
                            </li>
                            <li>
                                <p>&#10004;&#65039; Reversing time-bound and dimension-bound limitations</p>
                            </li>
                        </ul>

                        <div className="cost-section">
                            <h3>The longer you stay, the larger your healing library grows!</h3>

                            <h3>Special Early Enrollment Pricing &amp; Lifetime Savings</h3>

                            <h3>Join now for only $97/month and lock in your price for life!</h3>

                        </div>

                        <StyledRegisterNowButton
                            onClick={() => {
                                window.location.href = "https://buy.stripe.com/5kA9AFeLJfbq8241av";
                            }
                            }>Secure Your Spot</StyledRegisterNowButton>
                    </StyledProgramLanderArticle>
                </StyledProgramLanderSectionBlock>
                <div></div>
            </Container>

            <Container>
                <StyledProgramLanderSectionBlock>
                    <StyledProgramLanderArticle>

                        <hr className="icon" />
                        <h2>Powerful Bonuses to Elevate Your Transformation</h2>

                        <p><b>Bonus #1:</b> Complete Command Healing Program (<b>$497 Value – FREE!</b>) A full pre-recorded training from Master Kaye Lee, teaching you <b>how to command energy with pinpoint accuracy</b> — so you can accelerate healing and transformation on demand.</p>

                        <p><b>Bonus #2:</b> 12 Permissioning Audio Teachings by Karina Grant (<b>$297 Value - FREE!</b>) Powerful pre-recorded teachings designed to help you <b>release resistance, step into your power, and rewire your energy for success</b>.</p>

                        <p><b>Bonus #3:</b> The Permission to Sleep Program (<b>$97 Value - FREE!</b>) Can't sleep? This program includes 3 breakthrough techniques to clear stress, relax deeply, and fall asleep effortlessly every night.</p>

                        <h3>&#10024; A Huge Bonus: Karina Grant's Live Healing and Group Healing Container!</h3>

                        <p>Karina Grant has agreed to <b>run monthly live healing calls</b> and <b>healing containers</b> for this group as soon as it launches!</p>

                        <h3>&#128197; Karina's Live Group Healing Dates</h3>
                        <ul className="dates">
                            <li>Monday 31st March @ 3pm BST / 10am EST / 11am EDT</li>
                            <li>Wednesday April 30th @ 3pm BST / 10am EST / 11am EDT</li>
                        </ul>

                        {/* <KarinaDates /> */}
                        <p>Karina has scheduled the group heaings once a month.</p>

                        <h3>Monthly group healing calls exclusively for Divine Reversal Codes members.<b> From May</b> - Live manifestation training &amp; coaching calls to amplify your results.</h3>

                        <p><b>Exclusive Early-Action Bonus</b>: Unlock the Sacred Star &amp; Discharge Wand Healing Techniques!</p>

                        <p><b>Register now</b>, and you'll receive an <b>exclusive, extra bonus session</b> within the first two months—revealing powerful, little-known healing techniques that have remained hidden until now.</p>

                        <p><b>The Sacred Star Activation</b> – A divine method to bless yourself, activate your full potential, and unlock your highest energy frequencies.</p>

                        <p><b>The Discharge Wand Technique</b> – Learn to clear, neutralize, and dissolve distortions, energy blockages, and unwanted influences with precision and ease.</p>

                        <p><b>Outer, Inner, and Secret Instructions</b> – You won't just learn the basics—you'll receive all three levels of mastery, so you can experience profound energetic shifts and command your own healing like never before.</p>

                        <p><b>This session is ONLY available to those who join during early enrollment!</b></p>

                        <p>Secure your spot now, activate your Divine Reversal Codes, and claim this transformational bonus before it disappears!</p>

                        <StyledRegisterNowButton
                            onClick={() => {
                                window.location.href = "https://buy.stripe.com/5kA9AFeLJfbq8241av";
                            }
                            }>Secure Your Spot</StyledRegisterNowButton>

                    </StyledProgramLanderArticle>
                </StyledProgramLanderSectionBlock>
            </Container>

            <Container>
                <StyledProgramLanderSectionBlock>
                    <StyledProgramLanderArticle>
                        <hr className=" sparkles" />
                        <h2>Your New Reality Begins the Moment You Say YES</h2>

                        <p>What happens when you reverse the hidden forces limiting your health, success, and happiness?</p>

                        <h3>&#127775; You unlock the full power of your true self—without resistance or struggle. &#127775; You shift into a reality where healing is not only possible — it's inevitable. &#127775; You step beyond duality's limitations and reclaim the power that was always yours.</h3>

                        <p>But you must take the first step.</p>

                        <h3>Join now for only $97/month! Your transformation starts the moment you say YES.</h3>

                        <h3>&#128073; Click Below to Secure Your Spot &amp; Activate Your Divine Reversal Codes!</h3>

                        <StyledRegisterNowButton
                            style={{ marginTop: '2rem;' }}
                            onClick={() => {

                                window.location.href = "https://buy.stripe.com/5kA9AFeLJfbq8241av";
                            }
                            }>Secure Your Spot</StyledRegisterNowButton>
                    </StyledProgramLanderArticle>
                </StyledProgramLanderSectionBlock>



            </Container>

            <Container testimoinials>
                <StyledProgramLanderSectionBlock>
                    <article>
                        <StyledBlockQuote>
                            “Life changed so much since I got your program. I got all my family their own code.”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “Kaye is a master healer like no other. I can honestly say that he is the most incredible healer that I have been blessed to encounter.”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “Not only is he the most incredible healer, he has an amazing ability to read you and let you know what is going on for you and causing your issues. I found him to be 100% accurate.”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “A quick email to relay to you that my business has increased 5 fold after listening to the abundance codes.”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “Basically it's the most powerful sound healing I've ever experienced. I've had biofield tuning with tuning forks, healing with Himalayan singing bowls, crystal bowls, gong etc. but the effects of this are the most tangible & noticeable.
                            <br />
                            <br />
                            Thanks again & many thanks to Kaye.. so grateful!”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “Oh wow! Thank you so much Kaye..that was so powerful and uplifting 🙏❤️<br /><br />
                            I also want to mention that ever since my Mum has been signed up in your program..her Parkinson's symptoms have reduced and she is now eating with her left hand after at least 7-8 years! We are so blessed to know you! 🙏❤️🙏❤️🙏❤️”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “As a person diagnosed with MS more than 17 years ago, the chronic symptoms of fatigue, numbness, cold and hot sensitivity, muscle stiffness, and pain, are something you must handle daily... I am optimistic for my future and so grateful for his Work.”
                            <cite>Barbara, St. Louis, MO USA</cite>
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “I would like to give Kaye Lee feedback on the 4th package B call. I had an unexpected shift. I have been trying to shift resentfulness when having to help my husband (also in group) because of his ADD... I am very grateful.”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “It's so worth clearing my schedule for you, Kaye, no matter the time or circumstances! 🤗 Celebrating an unexpected $120/month more in my pension check! Yeah! 😃🤗🙏”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “Had a vaccination last week that was still giving me referred pain. Listened to some lovely energy work from Kaye Lee. Delighted to feel some ease so quickly today, and still taking care not to over-strain muscles.”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “Omg I just checked and the ventral hernia I asked Kaye to help me heal which was over my intestines is GONE!!!”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “Scar at age of 6 is gone.”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “My energy physically is off the roof which I'm enjoying.”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “Kaye Lee is a great healer. He is my first healer who opened my eyes and gave me hope for a better way of living...”
                        </StyledBlockQuote>
                    </article>

                    <article>
                        <StyledBlockQuote>
                            “I also wanted to let him know how much I am enjoying the program. I sleep better, I have more energy, and my cheeks look fuller. Thank you, Kaye!”
                        </StyledBlockQuote>
                    </article>

                    <footer>
                        <p><small>Note: Testimonials reflect individual experiences. Results vary, and no specific outcomes are guaranteed.</small></p>
                    </footer>
                    <StyledRegisterNowButton

                        onClick={() => {

                            window.location.href = "https://buy.stripe.com/5kA9AFeLJfbq8241av";
                        }
                        }>Secure Your Spot</StyledRegisterNowButton>
                </StyledProgramLanderSectionBlock>

                <div className="disclaimer">
                    <p>DISCLAIMER: The information on this site is not intended or implied to be a substitute for professional medical advice, diagnosis, or treatment. Please see a medical professional if you need help with depression, illness, or have any concerns whatsoever.</p>

                    <p>WE DO NOT OFFER MEDICAL ADVICE, COURSE OF TREATMENT, DIAGNOSIS OR ANY OTHER OPINION on your conditions or treatment options. SERVICES OR PRODUCTS THAT YOU OBTAIN THROUGH THIS WEBSITE are for information purposes only and not offered as medical or psychological advice, guidance or treatment.</p>

                    <p>Please note that products and claims on this site and any Company linked sites have not been evaluated by official institutions and are not meant to diagnose, prevent, treat or cure any illness or disease.</p>
                </div>
            </Container>
        </>
    );
};

export default CopyContent;