import React from "react"
import {
  StyledHeroBanner,
  ChildWrapper,
} from "../styledComponents/heroBannerStyles/heroBanner"
import { ChildWrapperProgram, StyledHeroBannerProgram } from "../styledComponents/heroBannerStyles/heroBannerProgram"

const HeroBannerProgram = ({ 
    children, 
    image,
    imageLarge,
    backgroundPosition, 
    page, 
    noBackground, 
    largeBackgroundPosition, 
    largeBackgroundSize, 
    mediumBackgroundPosition, 
    mediumBackgroundSize,
    backgroundSize,
    type,
    secondimage
  }) => {
  return (
    <>
        <StyledHeroBannerProgram
          noBackground={noBackground}
          image={image}
          imageLarge={imageLarge}
          backgroundPosition={backgroundPosition}
          page={page}
          mediumBackgroundPosition={mediumBackgroundPosition}
          mediumBackgroundSize={mediumBackgroundSize}
          largeBackgroundPosition={largeBackgroundPosition}
          largeBackgroundSize={largeBackgroundSize}
          type={type}
          className={type}
          backgroundSize={backgroundSize}
          secondimage={secondimage}> 
          <ChildWrapperProgram>{children}</ChildWrapperProgram>
        </StyledHeroBannerProgram>
    </>
  )
}

export default HeroBannerProgram
