import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components
const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  // max-width: 640px;
  margin: 0rem auto 4rem auto;
`;

const Thumbnail = styled.img`
  width: 100%;
  cursor: pointer;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  height: 68px;
  background-image: url('https://www.karinagrant.co.uk/images/video/play-button.png');
  background-size: cover;
  border: none;
  cursor: pointer;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 360px;
  border: none;
`;


export {
  VideoWrapper,
  Thumbnail,
  PlayButton,
  Iframe
}